<template>
    <v-card>
        <v-card-title class="text-h3 mb-2">Aide / Support</v-card-title>
        <v-card-subtitle>Vous rencontrez des problèmes ? Vous trouverez des réponses à vos questions ici.</v-card-subtitle>
        <v-card-text>
            
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name : 'Help',
}
</script>